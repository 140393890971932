<template>
  <div>
    <a-card style="margin-bottom: 24px">
      <a-row type="flex" :gutter="[16, 16]">
        <a-col :lg="6" :md="12" :xs="24" class="option-wrapper">
          <span>领域：</span>
          <h-cascader
            v-model="field"
            :options="mainFieldsOptions"
            :fieldNames="{
              label: 'name',
              value: 'id',
              children: 'children',
            }"
            change-on-select
            allowClear
            placeholder="全部分类"
            style="flex: 1"
            @change="initData"
          />
        </a-col>
        <a-col :lg="6" :md="12" :xs="24" class="option-wrapper">
          <span>状态：</span>
          <h-select v-model="query.status" allowClear placeholder="全部状态" style="flex: 1" @change="() => initData()">
            <a-select-option v-for="statusInfo in statusOptions" :key="statusInfo.value" :value="statusInfo.value">
              {{ statusInfo.label }}
            </a-select-option>
          </h-select>
        </a-col>
        <a-col :lg="6" :md="12" :xs="24" class="option-wrapper">
          <span>等级：</span>
          <h-select v-model="query.level" allowClear placeholder="全部等级" style="flex: 1" @change="() => initData()">
            <a-select-option v-for="levelInfo in levelOptions" :key="levelInfo.value" :value="levelInfo.value">
              {{ levelInfo.label }}
            </a-select-option>
          </h-select>
        </a-col>
        <a-col :lg="6" :md="12" :xs="24" class="option-wrapper">
          <span>搜索：</span>
          <a-input-search
            v-model="query.name"
            allowClear
            placeholder="请输入技能包名称"
            style="flex: 1"
            @search="initData"
          />
        </a-col>
      </a-row>
    </a-card>

    <a-card title="技能包列表" :body-style="{ padding: 0 }">
      <div slot="extra">
        <a-space>
          <a-button
            v-if="$auth('skills_base_sorts_select')"
            type="primary"
            shape="round"
            @click="$router.push('/skill-management/skill/base')"
          >
            <h-icon type="icon-Sorter-PositiveOrder"></h-icon><span>基础技能排序</span>
          </a-button>
          <a-button
            v-if="$auth('skills_add')"
            type="primary"
            shape="round"
            @click="$router.push('/skill-management/skill/create')"
          >
            <a-icon type="plus" /><span>新增技能</span>
          </a-button>
        </a-space>
      </div>

      <skill-table ref="table"></skill-table>
    </a-card>
  </div>
</template>

<script>
import * as commonApi from '@/api/common'
import { SKILL_STATUS, SKILL_LEVEL } from '@/store/dictionary'
import SkillTable from './components/Table'

export default {
  name: 'SkillManagementSkillList',
  components: {
    SkillTable,
  },
  data() {
    return {
      query: {
        fieldId: undefined,
        mainFieldId: undefined,
        status: undefined,
        level: undefined,
        name: undefined,
      },
      field: [],
      mainFieldsOptions: [],
      statusOptions: SKILL_STATUS,
      levelOptions: SKILL_LEVEL,
    }
  },
  activated() {
    this.initOptions()
  },
  mounted() {
    const { $nextTick, initData } = this

    $nextTick(() => {
      this.ready = true
    })

    initData()
  },
  methods: {
    initData() {
      const { field } = this

      this.query.mainFieldId = field[0]
      this.query.fieldId = field[1]

      this.$refs.table.initData(this.query)
    },
    initOptions() {
      commonApi.fieldsOptions().then((res) => {
        this.mainFieldsOptions = res.data.filter((v) => v.level === 'MAIN')
      })
    },
  },
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form /deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.ant-advanced-search-form /deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
</style>
